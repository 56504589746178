    import './scss/main.scss'
    const screenHeight = () => {
        var vh = window.innerHeight;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    const headerHeight = () =>{
        var headerh = document.querySelector('header').offsetHeight;
        document.documentElement.style.setProperty('--headerh', `${headerh}px`);
    }
    const mq = window.matchMedia('(max-width: 840px)');
    var openMenuBtn = document.getElementById('Menu__Check');
    const bodyOpen = document.querySelector('body');
    const headerMenu = document.querySelectorAll('.header__menu--nav');

    function init(){
        screenHeight();
        headerHeight();
    }



    // Hamburguer Menu
    function openMenu() {
        if (openMenuBtn.checked) {
            menuOpen();
        } else {
            menuClose();
        }
    }

    function menuClose() {
        bodyOpen.classList.remove('menu-open');
        headerMenu.forEach((menuNav) => {
            menuNav.classList.remove('menu-open');
        })
    }
    function menuOpen() {
        bodyOpen.classList.add('menu-open');
        headerMenu.forEach((menuNav) => {
            menuNav.classList.add('menu-open');
        })
    }

    // END Hamburguer menu


    window.onload = (event) => {
        console.log('page is fully loaded');
        init();

    };

    window.addEventListener('resize', screenHeight())

    openMenuBtn.addEventListener('click', () => {
        openMenu();
    });
